<template>
  <div v-if="alert">
    <!-- Edit form -->
    <FormAlert :baseAlert="alert" @submit-requested="submitRequested"></FormAlert>
  </div>
</template>
 
<script>
import { mapActions } from 'vuex'
import FormAlert from '../forms/FormAlert.vue'

export default {  
  data () {
    return {
      alert: null,
    }
  },
  components: { FormAlert },
  methods: {
    ...mapActions('alerts', ['editAlert']),
    submitRequested (alert) {
      this.editAlert(alert);
    },
  },
  mounted: function()
  {
    if(!this.$route.params.alert)
    {
      this.$router.push({ name: 'AlertsList', params: { alert: this.alert } });
    }
    else
    {
      this.alert = this.$route.params.alert;
    }
  }
}
</script> 
 
<style scoped>
</style> 